<template>
  <div class="container">
    <div class="header">
      <van-icon name="close" />
      <h4>Payment Cancel!</h4>
      <p>Please pay again</p>
    </div>
  </div>
</template>

<script>
import { openGet } from '@/apis2'

export default {
  name: 'Cancel',
  data() {
    return {
      data: {
        currencyType: '',
        orderPrice: '',
      },
    }
  },
  created() {
    // this.getConsultPayment()
  },
  methods: {
    getConsultPayment() {
      const clientOrderNo = localStorage.getItem('clientOrderNo') || ''
      openGet({ clientOrderNo }).then((data) => {
        this.data = data
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 26px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
</style>
